import React from "react";

// Customizable Area Start

import { jennyImage } from "./assets";
import { Box, Button, MenuItem, Select, styled, Typography, Paper} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


const TableComponent = styled(Box)({
  width:"100%",
  "& .tableText": {
    fontFamily: "Manrope",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "19.12px",
    letterSpacing: "0.01em",
    color: "#000000"
  },
  "& .tableHeadText": {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "21.86px",
    letterSpacing: "0.01em",
    color: "#5E5C5C"
  },
  "& .paidBtn": {
    maxWidth: "210px",
    width:"100%",
    minHeight: "43px",
    borderRadius: "10px",
    backgroundColor: "#5E70DD !important",
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "25.6px",
    color: "#FFFFFF",
    textTransform: "capitalize",
    "@media (max-width: 768px)": {
      width: "210px",
    },
  }

})
const StyledBox = styled(Box)({
  width:"100%",
  "@media (max-width: 768px)": {
    paddingLeft: "0px",
  },
  "& .bookText": {
    fontFamily: "Manrope",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "28.8px",
    color: "#25324B",
    paddingTop: "64px",
    paddingBottom: "26px",
    "@media (max-width: 768px)": {
      textAlign: "center",
    },
   },
  "& .selectBox": {
    maxWidth: "162px",
    width: "100%",
    height: "40px",
    borderRadius: "8px",
    border: "1px solid #D6DDEB",
    fontFamily: "Manrope",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "26px",
    letterSpacing: "-0.10000000149011612px",
    color:"#161C2D",
   },
   "& .boxAlignment": {
    display: "flex",
    gap: "16px",
    flexWrap: "wrap",
    paddingTop:"21px",
    paddingBottom:"51px",
    "@media (max-width: 768px)": {
      width: "100%",
      justifyContent: "center"
    },
   }

})

// Customizable Area End

import Rolesandpermissions2Controller, {
  Props,
  configJSON,
} from "./Rolesandpermissions2Controller";

export default class Rolesandpermissions2 extends Rolesandpermissions2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() { 
    this.getBookingRequestData()
    this.getCategoryApiData()
  }

  renderTable = () => {
    const rows = this.state.bookingRequestData?.requests || []
    return <TableComponent>
        <TableContainer style={{ boxShadow: "none" }} component={Paper}>
            <Table sx={{ width: "100%" }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                      <TableCell align="left" className="tableHeadText" style={{ width:"235px !important",}} >Senior</TableCell>
                        <TableCell className="tableHeadText">Service</TableCell>
                        <TableCell className="tableHeadText">Status</TableCell>
                        <TableCell align="left" className="tableHeadText" >Dates</TableCell>
                        <TableCell align="left" className="tableHeadText" >Phone number</TableCell>
                        <TableCell align="left" className="tableHeadText" >Location</TableCell>
                        <TableCell align="left" className="tableHeadText" ></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row: any) => (
                        <TableRow
                            key={"row.name"}
                            sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                '& td, & th': { border: 0 }
                            }}
                        >
                            <TableCell component="th" scope="row" >
                                <Box style={{ display: "flex", gap: "12px", height: "43px", alignItems: "center" }}>
                                    <img src={jennyImage.default} />
                                        <Typography className="rowNameText">{row.recipient_first_name} {row.recipient_last_name}</Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="left" className="tableText">{row.service_name}</TableCell>
                            <TableCell align="left" className="tableText">{row.status}</TableCell>
                            <TableCell align="left" className="tableText">{row.start_date}</TableCell>
                            <TableCell align="left" className="tableText">{row.recipient_phone_number}</TableCell>
                            <TableCell align="left">{row.address}</TableCell>
                            <TableCell align="left"><Button className="paidBtn">{configJSON.tablebtn}</Button></TableCell>
                        </TableRow>
                    ))}

                </TableBody>
            </Table>
        </TableContainer>
    </TableComponent>
  }
  // Customizable Area End

  render() {
    let services = this.state.categoriesData?.data?.[1].attributes.sub_categories	 || []
    return (
      // Customizable Area Start
      <Box style={{ width:"100%" }}>
        <StyledBox>
          <Box className="boxAlignment">
            <Select
              value={this.state.selectedService}
              className="selectBox"
              onChange={this.handleOnchange}
              displayEmpty
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                }
              }}
            >
              <MenuItem value=""> <span>Select</span></MenuItem>
              {services.map((item: any, index: number) =>
                <MenuItem key={index} value={index} >{item.name}</MenuItem>
              )}
            </Select>

            <Select
              value={1}
              className="selectBox"
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                }
              }}
            >
              <MenuItem key={1} value={1} >Dates</MenuItem>
            </Select>
          </Box>
        {this.renderTable()}
        </StyledBox>
      </Box>

      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webtyles = {
 
}


// Customizable Area End
