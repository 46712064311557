import React from "react";

// Customizable Area Start
import { Box, Typography, Button, TextField } from "@mui/material";
import { styled } from "@mui/styles";
import { LoginImage, imageLogo, headerLogo, Gradient } from "./assets";


const StyledBox = styled(Box)({
    width:"50%",
    position:"relative",
    "@media (max-width: 768px)": {
        display:"none"
      },
})
const StyledMainDiv = styled(Box)({
    justifyContent:"space-around",
    overflowX: "hidden",
    display:"flex",
   "@media (max-width: 457px)": {
       justifyContent:"center",
     backgroundColor:"#F1F5F9",
     },
})

const StyledTypo = styled("span")({
  fontFamily: "Inter",
  fontSize: "24px",
  fontWeight: 700,
  lineHeight: "32px",
  letterSpacing: "-0.11px",
  textAlign: "left",
 "@media (max-width: 768px)": {
  display:"none",
   },
})

const BoxWrap = styled("img")({
  display:"none",
 "@media (max-width: 768px)": {
  width: "157.04px",
  height: "16.91px",
  display: "block"
   },
})

const TextWrap = styled(Box)({
  "@media (max-width: 768px)": {
    display:"none",
  }
})
const RecWrap = styled(Box)({
  width:"80%",
  display: "flex",
  flexDirection: "column",
  "@media (max-width: 768px)": {
    width:"100%",
    alignItems:"center"
  }
})

const Container = styled(Box)({
  display:"flex",
  justifyContent:"space-between",
  "@media (max-width: 768px)": {
    justifyContent:"center",
    flexDirection: "column",
    alignItems: "center",
    gap:"20px"
  }
})

const Wrap = styled(Box)({
  display:"none",
  "@media (max-width: 768px)": {
    display: "flex",
    justifyContent: "center",
    width: "118%"
  }
})

const MobTypo = styled("span")({
  fontFamily: "Inter",
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "26px",
  textAlign: "left" as "left",
  marginBottom:"10px",
  "@media (max-width: 768px)": {
    fontFamily: "Manrope"
  }
})

const ContentTypo = styled("span")({
  fontFamily: "Inter",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left" as "left",
  marginBottom: "15px",
  "@media (max-width: 768px)": {
    fontFamily: "Manrope"
  }
})

const SpanTypo = styled("span")({
  fontFamily: "Inter",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "22px",
  textAlign: "left" as "left",
  color: "#5E70DD" ,
  "@media (max-width: 768px)": {
    fontFamily: "Manrope",
    lineHeight: "24px",
  }
})
const BackSpan = styled("span")({
  fontFamily: "Inter",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
  color:"#5E70DD",
  marginBottom: "15px",
  cursor: "pointer",
  "@media (max-width: 768px)": {
    fontFamily: "Manrope",
    lineHeight: "24px",
  }
})

const BackText = styled("span")({
  fontFamily: "Inter",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
   marginBottom:"15px",
   
  "@media (max-width: 768px)": {
    fontFamily: "Manrope",
  }
})

const Wrapper = styled(Box)({
  width: "80%",
  marginTop:"30px",
  display:"flex",
  flexDirection:"column",
})



// Customizable Area End

import ForgotPasswordLoginController, {
  Props,
} from "./ForgotPasswordController";
export default class ForgotPassword extends ForgotPasswordLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start


  renderForgotPasswordScreen = () => {
    return <Box style={{width:"50%", paddingTop:"40px", paddingBottom:"40px", display:"flex",justifyContent:"center"}}>
        <Box style={{width:"440px",height:"505px", paddingTop:"40px", paddingBottom:"40px"}}>
        <Box style={{display: "flex", justifyContent: "center", paddingTop: "20px"}}>
              <BoxWrap src={headerLogo.default} />
          </Box>
          <Box>
          <StyledTypo>Password recovery</StyledTypo>
          </Box>
            {!this.state.mailSend && 
          <Wrapper>
        <MobTypo>Forgot password?</MobTypo>
        <ContentTypo>No worries, we'll send a recovery link to your email.</ContentTypo>
        <Typography style={{...webStyles.commonTypo, marginBottom:"5px"}}>Email</Typography>
        <Box style={{marginBottom:"20px"}}>
        <TextField 
        style={{width: "360px", height: "56px",  borderRadius: "8px", }}
        placeholder="Your Email"
        name="email"
        value={this.state.loginValue}
        data-test-id= "forgotId"
        onChange={this.handleChange}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: `${this.state.validation && "#F87171"}`
                    },
                  },
                }}
        />
        {this.state.validation && <Typography style={webStyles.errorMsg}>Invalid email</Typography> }
        </Box>
        <Box style={{marginBottom:"20px", cursor: "pointer"}}>
        <Button style={{width: "360px",height: "56px", borderRadius:"8px", backgroundColor:"#5E70DD"}}
        data-test-id= "btnClickID"
        disabled={(!this.state.loginValue && !this.state.validation)}
         onClick={this.forgotApi}>
            <Typography style={{fontFamily: "Inter",fontSize: "16px",fontWeight: 700,lineHeight: "24px",
                textAlign: "left", color:"white", textTransform:"initial"
                }}>Send a recovery link</Typography>
            </Button>
            </Box>
            <TextWrap>
            <Typography style={{fontFamily: "Inter",fontSize: "16px",fontWeight: 400,
            lineHeight: "24px",textAlign: "left", marginBottom:"15px"}}>Back to <span style={webStyles.spanText}  onClick={this.handleNavigation.bind(this,"LoginPage")}>Login</span></Typography>
            </TextWrap>
            <Wrap><BackText> Back to <BackSpan data-test-id="navigateId" onClick={this.handleNavigation.bind(this,"EmailAccountRegistration")}>Sign up</BackSpan></BackText></Wrap>
                </Wrapper>}
               { this.state.mailSend &&
                <RecWrap>
                    <MobTypo>Check your email</MobTypo>
                    <ContentTypo>{`We have sent a password reset link to ${this.state.loginValue}`}</ContentTypo>
                    <Container>
                        <SpanTypo onClick={this.forgotApi} >Resend e-mail</SpanTypo>

                        <BackText>Back to <BackSpan data-test-id="navigateId" onClick={()=> this.handleNavigation("LoginPage")}>Login</BackSpan></BackText>
                    </Container>
                </RecWrap> }
    </Box>
    </Box>
  }

  // Customizable Area End
  // Customizable Area Start
  render() {
    return (
        <StyledMainDiv style={{backgroundImage: `url(${Gradient.default})`}}>
           {this.renderForgotPasswordScreen()}
           <StyledBox>
            <img src={LoginImage.default} style={{width:"100%", height: "100%", objectFit: "cover"}} />
            <img src={imageLogo.default} style={{ width:"152px", height: "114px", position: "absolute", top: "46px", right: "38%"}}/>

           </StyledBox>

        </StyledMainDiv>
    )
  }
  // Customizable Area End
}

export const webStyles = {
  // Customizable Area Start
    commonTypo: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: "left" as "left"
    },
    spanText: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "22px",
        textAlign: "left" as "left",
        color:"#5E70DD",
        cursor: "pointer"
    },
    errorMsg: {
      fontfamily: "Manrope",
      fontSize: "12px",
      fontSeight: 400,
      lineHeight: "18px",
      color: "#DC2626"
    }
  // Customizable Area End
}
