import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showPassword: boolean;
  errorStatus: boolean;
  success: boolean;
  loading: boolean;
  loginValue: string;
  error: boolean;
  responseMessage: string;
  passwordValue: string;
  loginValidationError: string;
  rememberMe: boolean;
  renderTabs: string;
  mailSend: boolean;
  passwordValidationError: string;
  showConfirmPassword: boolean;
  showPasswordCriteria: boolean;
  passwordError: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ResetPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  labelTitle: string = "";
  loginApiCallId: string = "";
  validationApiCallId: string = "";
  apiEmailLoginCallId: string = "";
  changePasswordCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationMessage),
    ];

    this.state = {
        error: false,
        errorStatus: false,
        success: false,
        loading: false,
        showPassword: false,
        showConfirmPassword: false,
        passwordValue: "",
        loginValue: "",
        rememberMe: false,
        responseMessage: "",
        passwordValidationError: "",
        loginValidationError: "",
        renderTabs:"LOGIN",
        mailSend: false,
        showPasswordCriteria: false,
        passwordError: "",
    };

    this.labelTitle =configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if ( getName(MessageEnum.RestAPIResponceMessage) === message.id ) {
      const apiRequestCallId = message.getData( getName(MessageEnum.RestAPIResponceDataMessage)); 
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if (apiRequestCallId === this.changePasswordCallId) {
        if (responseJson.error) {
          this.setState({responseMessage: responseJson.error, errorStatus: true })
        } else {
          this.setState({responseMessage: responseJson.message, success: true });
          setTimeout(() => {
            this.handleNavigation("LoginPage");
          }, 2000)
        }
      } 

      this.setState({
        loading: false
      })
    }
    
    
    // Customizable Area End
  }



   // Customizable Area Start
  handleChange = (events: React.ChangeEvent<HTMLInputElement>) => {
    let { value, name } =  events.target
    if( name == "email" ) {
      this.setState({ loginValue: value })
    }else{ this.setState({ passwordValue: value }) }
  }
  handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  }; 

  handleShowPassword = () =>{
    this.setState({showPassword: !this.state.showPassword})
  }

  handleNavigation = (route: string) => {
    const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigate.addData(getName(MessageEnum.NavigationTargetMessage), route);
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigate);
  }

  handleClickShowConfirmPassword = () => {
    this.setState({showConfirmPassword: !this.state.showConfirmPassword })
  }
  handleClickShowPassword = () => {
    this.setState({showPassword: !this.state.showPassword})
  }

  changePasswordApi = ( values: { password: string, confirmPassword: string }) => {
    let tokenData = JSON.parse(String(localStorage.getItem("login")))
     this.setState({mailSend: true})
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": tokenData?.token
    };
    let changePasswordToken =  window.location.href.split("=") 
    this.setState({
      loading: true,
    })
    let body = {
      "email": tokenData?.email,
      "token": changePasswordToken[1],
      "new_password": values.confirmPassword
      }

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.changePasswordCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePasswordEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
    // Customizable Area End
}
