import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    progress: number;
    currentTab: number;
    fieldValue: string;
     options: any[];
     radioValue: boolean
     secondRadioValue: boolean,
     teenPreferenceData: any
     radioStatus: string
     userDob:string
     parentFullname: string
     parentEmail: string
     parentCellno: string
     userFullName: string
     userTitle: string
     userWorkEmail: string
     firstReccomondName: string
     firstReccomondEmail: string
     secondReccomondName: string
     secondReccomondEmail: string
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class TeenPreferenceController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    APiCallId: string = "";
    teenPreferenceCallId: string = "";

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            progress: 0,
            currentTab: 0,
            fieldValue: "",
             options: [
                { id: 1, label: 'Elgin St. Celina' },
                { id: 2, label: '6391 Elgin St. Celina, Delaware 10299' },
              ],
              radioValue: false,
              secondRadioValue: false,
              teenPreferenceData: {},
              radioStatus: "",
              userDob: "",
              parentFullname: "",
              parentEmail: "",
              parentCellno: "",
              userFullName: "",
              userTitle: "",
              userWorkEmail: "",
              firstReccomondName: "",
              firstReccomondEmail: "",
              secondReccomondName: "",
              secondReccomondEmail: ""
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId === this.teenPreferenceCallId) {
            this.setState({ teenPreferenceData: responseJson })
        }

        // Customizable Area End
    }
    // Customizable Area Start

  handlePrevious = () => {
    this.setState({progress: this.state.progress - 14.28, currentTab: this.state.currentTab-1})
  }

  handleNext = () => {
        this.setState({progress: this.state.progress + 14.28, currentTab: this.state.currentTab+1})
  }
  handleChange = (event: any) => {
    this.setState({fieldValue: event.target.value})
  }
  handleRadio = (event: any) => {
    let { name, id } = event.target
    if(name === "radio1"){
        this.setState({radioValue: true, secondRadioValue: false, radioStatus: id})
    }else{
        this.setState({secondRadioValue: true , radioValue: false, radioStatus: id}) 
    }
  }

    async componentDidMount() {
        this.teenPreferenceApi()
    }

    teenPreferenceApi = () => {
        let data = JSON.parse(String(localStorage.getItem("login")))
        const header = {
            "Content-Type": configJSON.contenttypeApiValidateMobileNo,
            token: data?.token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.teenPreferenceCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.teenPreferenceApiEndpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify({
                "data": {
                    "looking_for": "job",// hire
                    "date_of_birth": "1975/12/20",
                    "address": "2726 Bagwell Avenue",
                    "subcategory_changes": ["Laundry", "Water House Plants", "Change Lightbulb"],
                    "coach_one_full_name": "Coach-3",
                    "coach_one_email": "coach@gmail.com",
                    "coach_two_full_name": "Coach-2",
                    "coach_two_email": "coach2@gmail.com",
                    "parent_full_name": "John Doe",
                    "parent_school_emails": "john@gmail.com",
                    "parent_phone_number": "123456789",
                    "representative_name": "Rep-1",
                    "representative_email": "represen@gmail.com",
                    "representative_contact": "Trusty",
                    "representative_title": "12345678987654"
                }
            })
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleChangeData = (event: any) => {
        let { name, value } = event.target
        if (name == "dob") {
            this.setState({ userDob: value })
        } else if (name == "parentName") {
            this.setState({ parentFullname: value })
        } else if (name == "parentEmail") {
            this.setState({ parentEmail: value })
        } else {
            this.setState({ parentCellno: value })
        }
    }

    handleRepresentData = (event: any) => {
        let { name, value } = event.target
        if (name == "userFullName") {
            this.setState({ userFullName: value })
        } else if (name == "userTitle") {
            this.setState({ userTitle: value })
        } else {
            this.setState({ userWorkEmail: value })
        }

    }

    handleRecommondation = (event: any) => {
        let { name, value } = event.target

        if (name == "firstSuggestName") {
            this.setState({ firstReccomondName: value })
        } else if (name == "firstSuggestEmail") {
            this.setState({ firstReccomondEmail: value })
        } else if (name == "secondSuggestName") {
            this.setState({ secondReccomondName: value })
        } else {
            this.setState({ secondReccomondEmail: value })
        }
    }

    // Customizable Area End

}

