import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Checkbox,
    Input,
    Radio,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { logo, prevIcon, nextIcon, bottomImage, userIcon } from "./assets"
import LinearProgress from '@mui/material/LinearProgress';

import TeenPreferenceController, {
    Props,
} from "./TeenPreferenceController";

const MainContainer = styled(Box)({
    width:"100%",
    overflowX:"hidden",
    "@media (max-width: 600px)": {
    },
    "& .navBox": {
        height: "74px",
        padding: "0px 124px 0px 84px",
        border: "1px solid #C1C7CD",
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        "@media (max-width: 768px)": {
            padding: "0px",
            justifyContent:"space-around",
        },
    },
    "& .nameBox": {
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        maxWidth:"213px",
        width:"100%",
        borderLeft:"1px solid #D6DDEB",
        height:"48px",
        "@media (max-width: 768px)": {
            display: "none"
        },
    },
    "& .userIcon": {
        display:"none",
        "@media (max-width: 768px)": {
            display: "block"
        },
    },

    "& .nameText": {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "25.6px",
        color: "#39393F"
    },
    "& .commonTypo": {
        fontFamily: "Manrope",
        fontSize: "28px",
        fontWeight: 400,
        lineHeight: "33.6px",
        color:"#25324B"
    },
    "& .optionText": {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "21.86px",
        color: "#252729",
        width:"701px"
    },
    "& .radioTextAlign": {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        width: "100%",
        maxWidth:"701px",
        "@media (max-width: 768px)": {
            width: "58%",
        },
    },
    "& .prevBtn": {
        maxWidth: "205px",
        width:"100%",
        height: "43px",
        borderRadius: "10px",
        border: "1px solid #5E70DD" 
    },
    "& .nextBtn": {
        maxWidth: "205px",
        width:"100%",
        height: "43px",
        borderRadius: "10px",
        backgroundColor: "#5E70DD !important"
    },
});

const BirthContainer = styled(Box)({
        display:"flex",
        alignItems:"center",
        flexDirection:"column",
        gap:"32px",

    "& .inputBox": {
        maxWidth: "360px",
        height: "56px",
        width:"100%",
        borderRadius: "10px",
        border: "1px solid #CBD5E1",
        paddingLeft:"26px",
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color:"#0F172A"
},
    "& .selectBox": {
        maxWidth: "360px",
        width:"100%",
        height: "56px",
        borderRadius: "10px",
        border: "1px solid #CBD5E1",
        fontFamily: "Manrope",
        paddingLeft: "26px",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#000000"
    },
    "& .menuText": {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "21.86px",
        color: "#252729",
        border: "1px solid #F4F4F4"
    }
})

const CommunityWrap = styled(Box)({
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "24px",
        maxWidth: "773px",
        width: "100%",
    "& .titleText": {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "21.86px",
        color:"#25324B"
    },
    "& .contentText": {
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "16.39px",
        color: "#818486"
    }
})

const BoxWrap = styled(Box)({
        maxWidth:"507px",
        width:"100%",
        display: "flex",
        flexDirection: "column",
        gap: "9px",
        alignItems: "center",
    "& .infoText": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "19.12px",
        color: "#818486",
        textAlign: "center",
    },
    "& .labelText": {
        fontFamily: "Manrope",
        fontSize: "28px",
        fontWeight: 400,
        lineHeight: "33.6px",
        color: "#25324B",
        paddingBottom: "10px"
    },
    "& .commonInp": {
        maxWidth: "360px",
        width:"100%",
        height: "56px",
        border: "1px solid #CBD5E1",
        borderRadius: "10px",
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#0F172A",
        paddingLeft: "26px"
    }

})

const RepresentWrap = styled(Box)({
        maxWidth: "770px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
    "& .inputDiv": {
        maxWidth: "360px",
        width:"100%",
        height: "56px",
        borderRadius: "10px",
        border: "1px solid #CBD5E1",
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#0F172A",
        paddingLeft: "26px"
    },
    "& .labelText": {
        fontFamily: "Manrope",
        fontSize: "28px",
        fontWeight: 400,
        lineHeight: "33.6px",
        color: "#25324B",
        paddingBottom: "16px"
    },
    "& .infoText": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "19.12px",
        color: "#818486",
        textAlign: "center",
        paddingBottom: "40px"
    },
    "& .manageAlign": {
        display: "flex",
        gap: "48px",
        width: "100%",
        alignItems: "center",
        flexWrap: "wrap",
        "@media (max-width: 768px)": {
           flexDirection:"column"
        }
    }
})

const RecomendWrap = styled(Box)({
       maxWidth:"894px",
        width:"100%",
    "& .recmTetx": {
        fontFamily: "Manrope",
        fontSize: "28px",
        fontWeight: 400,
        lineHeight: "33.6px",
        color: "#25324B",
        paddingBottom: "24px",
        paddingTop:"30px"
    },
    "& .abtRecm": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "19.12px",
        color: "#000000"
    },
    "& .spanText": {
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "16.39px",
        color: "#818486"
    },
    "& .fullText": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "19.12px",
        color: "#25324B"
    },
    "& .comInput": {
        width: "360px",
        height: "56px",
        paddingLeft: "16px",
        borderRadius: "10px",
        border: "1px solid #CBD5E1",
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#0F172A"
    },
    "& .alignBoxinput": {
        display: "flex",
        justifyContent: "space-between",
        "@media (max-width: 768px)": {
            display: "flex",
            justifyContent: "center",
            alignItems:"center",
            gap:"20px",
            flexDirection:"column"
         }
    },
    "& .contALign": {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        "@media (max-width: 768px)": {
        textAlign: "center",
        justifyContent: "center"
        }
    },
    "& .littleText": {
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "16.39px",
        color: "#818486"
    },
    "& .bottomText": {
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "16.39px",
        color: "#818486",
        textAlign: "center",
        paddingTop:"37px"
    },
    "& .skipText": {
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 800,
        lineHeight: "16.39px",
        color:"#0085FF"
    }

})


export default class TeenPreference extends TeenPreferenceController {
    constructor(props: Props) {
        super(props);

    }

    renderDescribeScreen = () => {
        return <Box style={{display:"flex", alignItems:"center", flexDirection: "column", gap:"24px", width:"100%"}}>
            <Typography className="commonTypo">What best describes you?</Typography>

            <Box className="radioTextAlign">
                <Box style={{display: "flex", justifyContent: "space-around", alignItems: "center"}}>
                    <Radio name="radio1" 
                     checked={this.state.radioValue}
                     id="job"
                     onClick={this.handleRadio}
                     sx={{'&.Mui-checked': {
                        color: '#5E70DD',
                      },}}
                     />
                    <Typography className="optionText">I am enrolled in high school (or dual credit / running start), and I want a part-time job assisting senior citizens in my community.</Typography>
                 </Box>
                 <Box style={{display: "flex", justifyContent: "space-around", alignItems: "center"}}>
                    <Radio 
                     id="hire"
                    checked={this.state.secondRadioValue}
                     onClick={this.handleRadio}
                     sx={{
                        '&.Mui-checked': {
                        color: '#5E70DD',
                      }
                    }}
                      />
                    <Typography className="optionText">I am enrolled in college or trade school, and I want a part-time job assisting senior citizens in my community.</Typography>
                 </Box>
            </Box>

        </Box>
    }

    renderBirthDateScreen = () => {
        return <BirthContainer>
            <Typography className="commonTypo">What’s your birth date?</Typography>
            <Input type="text" name="dob" placeholder="DD/MM/YYYY" className="inputBox" disableUnderline onChange={this.handleChangeData}  />
        </BirthContainer>
    }

    renderAddressScreen = () => {
        return <BirthContainer>
            <Typography className="commonTypo">What’s your home address?</Typography>
            <Box style={{maxWidth: "360px",  width:"100%"}}>
            <Input 
            type="text"
            className="selectBox"
            disableUnderline
            onChange={this.handleChange}
             value={this.state.fieldValue}
            />
            { (this.state.fieldValue) && 
            <Box style={{ borderTopRightRadius: "10px", borderTopLeftRadius: "10px", paddingTop:"10px", maxWidth: "360px", width: "100%", border:"1px solid #F4F4F4" }}>
                {
            this.state.options.map((item: any)=>
                <Typography className="menuText"  style={{ paddingLeft:"16px", paddingBottom:"10px"}}
             onClick={this.handleChange.bind(this,{target:{value: item.label}})} >{item.label}</Typography>
            )
                }
            </Box>}
                </Box>
        </BirthContainer>
    }

    renderSchoolScreen = () => {
        return <BirthContainer>
            <Typography className="commonTypo">What school do you go to?</Typography>
            <Box style={{maxWidth: "360px",  width:"100%"}}>
            <Input 
            type="text"
            className="selectBox"
            disableUnderline
            onChange={this.handleChange}
             value={this.state.fieldValue}
            />
            { (this.state.fieldValue) && 
            <Box style={{ borderTopRightRadius: "10px", borderTopLeftRadius: "10px", paddingTop:"10px", maxWidth: "360px", width: "100%", border:"1px solid #F4F4F4" }}>
                {
            this.state.options.map((item: any)=>
                <Typography className="menuText"  style={{ paddingLeft:"16px", paddingBottom:"10px"}} onClick={this.handleChange.bind(this,{target:{value: item.label}})} >{item.label}</Typography>
            )
                }
            </Box>}
                </Box>
        </BirthContainer>
    }

    renderCommunityScreen = () => {
    const dataObject = [
        {"title": "Tech Help", content:"Troubleshoot and fix minor issues with apps, logins, or wifi for phone, laptop, or other devices."},
        {"title": "Indoor Help", content:"Change lightbulbs, carry in boxes or groceries, water and maintain houseplants, help with meal prep, get laundry and housekeeping done, and more."},
        {"title": "Outdoor Help", content:"Mow and edge a lawn, plant seeds or starters, spread mulch, blow or rake debris, and more."},
        {"title": "Friendly Visits", content:"Visit, play games or help with hobbies, and ask if the senior needs help with anything."},
        {"title": "Organizing", content:"Sort through, organize, box up, label, and arrange items in cabinets, closets, garage, or shed. If the client has  items to sell online or donate, you can help with this too."},
        {"title": "Pet Care", content:"Walk dogs, change litter boxes, fill pet food bins, brush or play with furry family members, clean out the fish tank, and more."},
        {"title": "Run Errands", content:"Use your own reliable transportation to pick up groceries or small household items, pick up mail or packages, drop off items for shipping, take items to charity donation sites, and more."}

    ]
        return <CommunityWrap>
        <Typography className="commonTypo">How can you assist seniors in your local community?</Typography>
            {
                dataObject.map((item) => <Box style={{display: "flex", alignItems: "center", gap:"10px"}}>
                    <Checkbox 
                    sx={{
                        '&.Mui-checked': {
                            color: '#5E70DD',
                        },
                    }} />
                <Box>
                <Typography className="titleText">{item.title}</Typography>
                <Typography className="contentText">{item.content}</Typography>
                </Box>
                </Box>
                )
            }
        
        </CommunityWrap>
    }

    renderParentInfoScreen = () => {
        return <BoxWrap>
            <Typography className="infoText">Please provide your parent or legal guardian's information as a safety contact. If you are under 18, we will also contact them to sign your work authorization and background check permission forms.</Typography>
                <Box style={{width:"100%", maxWidth:"360px", display:"flex", flexDirection: "column", gap: "16px"}}>
                    <Box>
                        <Typography className="labelText">Parent’s Full Name</Typography>
                        <Input
                        type="text"
                        placeholder="Anna Hirot"
                        className="commonInp"
                        disableUnderline
                        value={this.state.parentFullname}
                        onChange={this.handleChangeData}
                        name="parentName"
                        />
                    </Box>
                    <Box>
                        <Typography className="labelText">Parent’s Email</Typography>
                        <Input
                        type="text"
                        className="commonInp"
                        placeholder="email@email.com"
                        disableUnderline
                        value={this.state.parentEmail}
                        onChange={this.handleChangeData}
                        name="parentEmail"
                        />
                    </Box>
                    <Box>
                        <Typography className="labelText">Parent’s Cel Number</Typography>
                        <Input
                        type="text"
                        className="commonInp"
                        placeholder="(201) 555-5555"
                        disableUnderline
                        value={this.state.parentCellno}
                        onChange={this.handleChangeData}
                        />
                    </Box>

                </Box>
        </BoxWrap>
    }

    renderRepresentativeScreen = () => {
        return <RepresentWrap>
            <Box style={{textAlign: "center"}}>
            <Typography className="commonTypo" style={{paddingBottom: "16px"}}>School Representative </Typography>
            <Typography className="infoText">Please provide contact information for your academic counselor to verify your school enrollment. If you are under 18, we will also contact them to sign your work authorization form.</Typography>
            </Box>
            <Box>
                <Box className="manageAlign">
                <Box style={{maxWidth: "360px",  width:"100%"}}>
                    <Typography className="labelText">Full Name</Typography>
                    <Input
                    type="text"
                    className="inputDiv"
                    placeholder="Ben Ugio"
                    disableUnderline
                    name="userFullName"
                    onChange={this.handleRepresentData}
                    value={this.state.userFullName}
                    />
                </Box>
                <Box style={{maxWidth: "360px",  width:"100%"}}>
                    <Typography className="labelText">Title</Typography>
                    <Input
                    type="text"
                    className="inputDiv"
                    placeholder="1702 Lake Roberts Landing Dr"
                    disableUnderline
                    name="userTitle"
                    onChange={this.handleRepresentData}
                    value={this.state.userTitle}
                    />
                </Box>
                 <Box style={{maxWidth: "360px",  width:"100%"}}>
                    <Typography className="labelText" >Work Email</Typography>
                    <Input
                    type="text"
                    className="inputDiv"
                    placeholder="email@email.com"
                    disableUnderline
                    onChange={this.handleRepresentData}
                    value={this.state.userWorkEmail}
                    />
                </Box>
                </Box>
            </Box>
        </RepresentWrap>
    }

    renderRecommendScreen = () => {
        console.log("recommond", this.state.firstReccomondEmail, this.state.firstReccomondName, this.state.secondReccomondName, this.state.secondReccomondEmail)
        return <RecomendWrap style={{width:"100%", maxWidth:"894px"}}>
            <Typography className="recmTetx" style={{textAlign: "center"}}>Recommendations</Typography>
            <Box>
                <Box className="contALign">
                <Box style={{maxWidth:"452px", width:"100%"}}>
                    <Typography className="abtRecm">About Recommendations:</Typography>
                    <Typography className="spanText">Seniors like seeing you're trusted by local adults who aren't family members. Recommenders should be adults who know you well but aren't related to you, like your high school counselor, teacher, principal, coach, religious community leader, or manager at another job.</Typography>
                </Box>
                <Box style={{maxWidth:"360px", width:"100%"}}>
                    <Typography className="abtRecm">It's a Quick Online Form - But Ask First, It's Polite!</Typography>
                    <Typography className="spanText">Ask your Recommenders for permission first, it's the polite thing to do! Let them know it's an online multiple choice form that only takes a  minute to fill out. They'll get an email from <span className="littleText">recommend@senioryear.co</span> inviting them to fill out the form.</Typography>
                </Box>
                </Box>
                <Box className="alignBoxinput">
                <Box>
                <Typography className="recmTetx" >Recommender #1</Typography>
                <Box style={{paddingBottom:"16px"}}>
                    <Typography className="fullText">Full Name</Typography>
                    <Input 
                    type="text"
                    className="comInput"
                    disableUnderline
                    placeholder="Anna Hirot"
                    value={this.state.firstReccomondName}
                    onChange={this.handleRecommondation}
                    name="firstSuggestName"
                    />
                </Box>
                <Box>
                    <Typography className="fullText">email</Typography>
                    <Input 
                    type="text"
                    className="comInput"
                    disableUnderline
                    placeholder="email@email.com"
                    value={this.state.firstReccomondEmail}
                    onChange={this.handleRecommondation}
                    name="firstSuggestEmail"
                    />
                </Box>
                </Box>
                <Box>
                <Typography className="recmTetx" >Recommender #2</Typography>
                <Box style={{paddingBottom:"16px"}}>
                    <Typography className="fullText">Full Name</Typography>
                    <Input 
                    type="text"
                    className="comInput"
                    disableUnderline
                    placeholder="Anna Hirot"
                    value={this.state.secondReccomondName}
                    onChange={this.handleRecommondation}
                    name="secondSuggestName"
                    />
                </Box>
                <Box>
                    <Typography className="fullText">email</Typography>
                    <Input 
                    type="text"
                    className="comInput"
                    disableUnderline
                    placeholder="email@email.com"
                    value={this.state.secondReccomondEmail}
                    onChange={this.handleRecommondation}
                    />
                </Box>
                </Box>
                </Box>
                <Typography className="bottomText">If you <span className="littleText">don't have their permission yet</span>, you can <span className="skipText">skip</span> this section for now.</Typography>
            </Box>

        </RecomendWrap>
    }

    handlescreens = () => {
        switch(this.state.currentTab){
            case 0:
                return this.renderDescribeScreen()
            case 1:
                return this.renderBirthDateScreen()
            case 2:
                return this.renderAddressScreen()
            case 3:
                return this.renderSchoolScreen()
            case 4:
                return this.renderCommunityScreen()
            case 5:
                return this.renderParentInfoScreen()
            case 6:
                return this.renderRepresentativeScreen()
            case 7:
                return this.renderRecommendScreen()
        }
    }
    render() {
        const positionValue = (this.state.currentTab < 4) ? "fixed" : "unset"
        return (
            <MainContainer>
                <Box className="navBox">
                    <img src={logo.default} />
                    <img src={userIcon.default} className="userIcon"/>
                    <Box className="nameBox">
                        <Typography className="nameText">David Jones</Typography>
                    </Box>
                </Box>

                <Box style={{ display:"flex", flexDirection: "column", alignItems:"center", justifyContent: "center", width:"100%", gap:"18px", paddingTop:"10px", height: `${(this.state.currentTab < 4) ? "55vh" : "100vh"}` }}>

                    <Box style={{width:"100%", maxWidth: "388px", display:"flex", alignItems:"center", justifyContent: "space-around"}}>
                        <Typography style={{fontFamily: "Manrope", fontSize: "18px",
                         fontWeight: 700, lineHeight: "24.59px", color:"#3174A5"}}>{Math.ceil(this.state.progress)}%</Typography>
                        <Typography style={{fontFamily: "Manrope", fontSize: "18px", fontWeight: 700,
                        lineHeight: "24.59px", color: "#1F2224"}}>Update in progress..</Typography>
                        <Typography style={{fontFamily: "Manrope", fontSize: "18px", fontWeight: 600,
                        lineHeight: "24.59px", color:"#8FA7B8"}}>2 mins left</Typography>
                    </Box>

                <Box style={{ width:"100%", maxWidth: "388px" , height: "5px", backgroundColor:"#C7D3EB"}}>
                <LinearProgress variant="determinate" value={this.state.progress}
                 sx={{
                        '& .MuiLinearProgress-bar1Determinate': {
                        backgroundColor: "#5E70DD",
                        },
                    }}/>
                </Box>
                 
                 <Box style={{width:"100%",display: "flex", justifyContent: "center" }}>
                   <Box> {this.handlescreens()}</Box>
                 </Box>

                </Box>
                <Box style={{width:"100%", height: "156px", marginTop:"55px", position: positionValue, bottom:"0px", backgroundSize:"cover", backgroundImage: `url(${bottomImage.default})`,
                display: "flex", justifyContent: "center",  alignItems: "center", gap: "16px" }}>
                <Button className="prevBtn" onClick={this.handlePrevious} disabled={(this.state.currentTab === 0)}><img src={prevIcon.default} /></Button>
                <Button className="nextBtn" onClick={this.handleNext} disabled={(this.state.currentTab === 7)} ><img src={nextIcon.default} /></Button>
                </Box>

            </MainContainer>
        )
    }
}
// Customizable Area End